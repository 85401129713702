
import {
    Component,
    Prop,
    Vue,
} from 'vue-property-decorator';

import { Inject } from 'inversify-props';
import { ChartOptions } from 'chart.js';

import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import MarketsHistoryService, { MarketsHistoryServiceS } from '@/modules/common/modules/markets-history/markets-history.service';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

import type Day from '@/modules/common/types/day.type';
import PopupEvents from '@/modules/events/components/popup-events.vue';
import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import ModalWrapper, { DialogOptions } from '@/modules/common/components/modal-wrapper.vue';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import MarketsHistoryFilters from './markets-history-filters.vue';
import MarketsHistoryHeader from './markets-history-header.vue';
import MarketsHistoryTable from './markets-history-table.vue';
import MarketsStatistics from './markets-statistics.vue';

@Component({
    components: {
        CustomGraph,
        CustomCheckbox,
        PopupEvents,
        ModalWrapper,
        MarketsHistoryHeader,
        MarketsHistoryFilters,
        MarketsHistoryTable,
        PopupEventsContainer,
        MarketsStatistics,
    },
})
export default class MarketsHistoryPopup extends Vue {
    @Inject(MarketsHistoryServiceS) private marketsHistoryService!: MarketsHistoryService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(MarketsServiceS) private marketsService!: MarketsService;

    @Prop({ type: Object })
    modalOptions!: DialogOptions;

    lastTooltipPos: string | null = null;

    async beforeMount() {
        if (this.isCluster) return;

        if (this.marketsService.isLoading) {
            await this.marketsService.storeState.loading.whenLoadingFinished();
        }

        this.marketsHistoryService
            .setMarketsData(this.marketsService.data[this.provider], {
                provider: this.provider,
            });
    }

    async mounted() {
        if (!this.marketsHistoryService.marketsDocument && this.isCluster) {
            (this.$refs.modalWrapper as any).triggerClose();
        }
        await this.marketsHistoryService.setTableDay();
    }

    get day() {
        return +this.$route.params.day;
    }

    get compsetId() {
        return this.$route.params.compsetId
            || this.documentFiltersService.settings.compsetId;
    }

    get hotelId() {
        return +this.$route.params.hotelId || this.userService.currentHotelId;
    }

    get provider() {
        return this.$route.params.source;
    }

    get isCluster() {
        return this.$route.name!.startsWith('cluster.');
    }

    private hiddenGraphs: {
        [hotelId: number]: boolean,
    } = {};

    get isEmptyGraph() {
        if (!this.chartData) return true;
        if (!this.chartData.datasets) return true;

        return !this.chartData.datasets
            .some(dataset => dataset.data && dataset.data
                .some(value => !!value));
    }

    get skeleton() {
        if (!this.isCluster) {
            return this.marketsHistoryService.isLoading
                || this.marketsService.isLoading;
        }

        return this.marketsHistoryService.isLoading;
    }

    get tableDay() {
        return this.marketsHistoryService.storeState.dayIndex;
    }

    get marketsHistory() {
        let documentSettings: DocumentFiltersModel | null = null;
        const compset = this.compsetsService.getCompset(this.compsetId);

        if (this.isCluster && compset) {
            documentSettings = {
                ...this.documentFiltersService.storeState.settings,
                compsetId: this.compsetId,
                los: compset.los.length ? compset.los[0] : null,
                pos: compset.mainPos || (compset.pos.length ? compset.pos[0] : null),
            };
        } else {
            documentSettings = this.documentFiltersService.storeState.settings;
        }

        return this.marketsHistoryService
            .getMarketsHistory(Number(this.day) as Day, this.hotelId, this.compsetId, documentSettings);
    }

    get options() {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 4,
                    backgroundColor: 'white',
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                    },
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        } as ChartOptions;
    }

    get currentHotelId() {
        return +this.$route.params.hotelId || this.userService.currentHotelId;
    }

    get chartData() {
        if (!this.marketsHistory) {
            return null;
        }

        if (!this.tableDay) {
            this.marketsHistoryService.setTableDay();
        }

        const labels = this.marketsHistory
            .map((_: any, index: number) => {
                if (index === 1) {
                    return `-  ${index - 1}  -`;
                }
                const day = index - 1;
                return String(day).length === 1 ? `-0${day}` : -day;
            })
            .reverse();

        labels.pop();

        const hotels = this.marketsHistoryService.marketHistoryHotels;

        if (!hotels) {
            return {
                labels: [],
                datasets: [],
            };
        }

        const datasets = hotels
            .map((hotel: number) => {
                const borderColor = hotel === this.currentHotelId
                    ? CURRENT_HOTEL_GRAPH_COLOR
                    : this.marketsHistoryService.getHotelColor(hotel, this.compsetId);

                return {
                    data: this.marketsHistoryService.getMarketHistoryHotelPositions(hotel),
                    pointBorderWidth: 1,
                    borderWidth: hotel === this.currentHotelId ? 3 : 2,
                    borderColor: this.hiddenGraphs[hotel]
                        ? 'transparent'
                        : borderColor,
                    lineTension: 0,
                    borderJoinStyle: 'round',
                };
            });

        return {
            labels,
            datasets,
        };
    }

    resetPopupData() {
        this.marketsHistoryService.marketsDocument = null;
        this.marketsHistoryService.setTableDay();
        this.handleTooltipClick();
    }

    handleTooltipClick(newPos?: string) {
        this.lastTooltipPos = newPos || null;
    }

    toggleGraph(hotelId: number) {
        if (this.hiddenGraphs[hotelId]) {
            this.hiddenGraphs = {
                ...this.hiddenGraphs,
                [hotelId]: false,
            };

            return;
        }

        this.hiddenGraphs = {
            ...this.hiddenGraphs,
            [hotelId]: true,
        };
    }
}
