var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.tableData)?_c('div',{staticClass:"table"},[(!_vm.isOutOfRange)?_c('p',{staticClass:"scanned-at"},[_vm._v(" "+_vm._s(_vm.$tc('date'))+" "+_vm._s(_vm.historyDate)+" ")]):_vm._e(),_c('div',{staticClass:"table__columns"},[_c('div',{staticClass:"table__column name"},[_c('p',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$tc('titles.hotelName'))}}),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + 'name',staticClass:"table__cell hotel-name",on:{"click":function($event){return _vm.$emit('legend:click', hotel.id)}}},[_c('div',{class:{
                        'graph-legend': true,
                        'graph-legend--hidden': _vm.hiddenGraphs[hotel.id],
                    },style:({
                        '--indicator-color': hotel.borderColor,
                    })}),_c('p',{class:{
                        'hotel-name__value': true,
                        'hotel-name__value--my' : hotel.myHotel
                    },attrs:{"title":hotel.name}},[_c('span',[_vm._v(_vm._s(hotel.name))])])])})],2),_c('div',{staticClass:"table__column position"},[_c('p',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$tc('titles.position'))}}),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + '_position',class:{
                    'table__cell': true,
                    'position__value': true,
                    'position__value--my' : hotel.myHotel,
                    'position__value--down': hotel.positionDiff && hotel.positionDiff > 0,
                    'position__value--up': hotel.positionDiff && hotel.positionDiff < 0,
                }},[(hotel.position !== -1 && hotel.price !== null)?_c('p',[_c('span',[_vm._v(" "+_vm._s(hotel.position)+" ")])]):(hotel.position === null)?_c('p',[_c('span',{domProps:{"textContent":_vm._s(_vm.$tc('na'))}})]):_c('p',[_c('span',[_vm._v("---")])])])})],2),_c('div',{staticClass:"table__column old-position"},[_c('p',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$tc('markets.popup.oldPosition'))}}),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + '_old_position',class:{
                    'table__cell': true,
                    'position__value': true,
                    'position__value--my' : hotel.myHotel
                }},[_c('span',{domProps:{"textContent":_vm._s(hotel.oldPosition || '---')}})])})],2),_c('div',{staticClass:"table__column page"},[_c('p',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$tc('titles.page'))}}),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + '_position',staticClass:"table__cell page__value"},[(hotel.page)?_c('p',{domProps:{"textContent":_vm._s(hotel.page)}}):_c('p',[_vm._v(" --- ")])])})],2),_c('div',{staticClass:"table__column promotion"},[_c('p',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.$tc('markets.popup.promotion'))}}),_vm._l((_vm.tableData),function(hotel,i){return _c('div',{key:i + '_promotion',class:{
                    'table__cell': true,
                    'position__value': true,
                    'position__value--my' : hotel.myHotel
                }},[(hotel.promoted)?[(_vm.promotionLogo)?_c('img',{attrs:{"src":_vm.promotionLogo,"alt":"promoted"}}):_c('i',{staticClass:"icon-Icons_cug"})]:_c('span',[_vm._v("-")])],2)})],2)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }